import React, { useState, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Loader from '../loader/Loader';
import { URLS } from '../../../constants/urls';
import M from 'materialize-css';

function ConUsuarios({ setLoaderMessage, setIsLoading, senha, idUser, tags, tests, customStyles }) {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    
    const [selectedUser, setSelectedUser] = useState(null); // Estado para o usuário selecionado

    const [isUserModalOpen, setIsUserModalOpen] = useState(false); // Estado para controlar o modal
    const [isEditSignModalOpen, setIsEditSignModalOpen] = useState(false);
    const [isUserAddModalOpen, setIsUserAddModalOpen] = useState(false);

    const [nomeUsu, setNomeUsu] = useState('');
    const [cpfUsu, setCpfUsu] = useState('');
    const [emailUsu, setEmailUsu] = useState('');
    const [telefoneUsu, setTelefoneUsu] = useState('');
    const [passUsu, setPassUsu] = useState('');

    const [userNameEdit, setUserNameEdit] = useState('');
    const [userEmailEdit, setUserEmailEdit] = useState('');
    const [userPassEdit, setUserPassEdit] = useState('');

    const [userList, setUserList] = useState([]);
    const [noUserFind, setNoUserFind] = useState(false);
    const [showUserList, setShowUserList] = useState(false);
    const [productList, setProductList] = useState([]); // Estado para a lista de produtos do usuário
    const [resultList, setResultList] = useState([]); // Estado para a lista de resultados do usuário

    const [selectedAddProduct, setSelectedAddProduct] = useState(''); // Estado para o produto a adicionar
    const [selectedRemoveProduct, setSelectedRemoveProduct] = useState(''); // Estado para o produto a remover

    // Função para fazer a requisição para criar um usuário
    const submitUserCreation = async (userData) => {
        try {
            const response = await axios.post(
                URLS.CREATE_USUARIO,
                userData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para fazer a requisição para atualizar um usuário
    const submitUserUpdate = async (userData) => {
        try {
            const response = await axios.post(
                URLS.UPDATE_USUARIO,  // URL correspondente à rota de atualização de usuário
                userData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para fazer a requisição para adicionar um registro de produto para um usuário
    const submitProductSubscription = async (productData) => {
        try {
            const response = await axios.post(
                URLS.ASSINA_PRODUTO,
                productData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para buscar usuários pelo nome no backend
    const fetchUsersByName = async (name) => {

        try {
            const response = await axios.post(URLS.READ_BY_NAME_USUARIO, {
                nome: name
            });

            if (response.data.success) {
                const filteredUsers = response.data.usuarios;
                
                const uniqueNames = Array.from(new Set(filteredUsers.map(user => user.nome)));
                setSuggestions(uniqueNames);

                setUserList(filteredUsers);
            } else {
                setSuggestions([]);
                setUserList([]);
            }
        } catch (error) {
            console.error('Erro ao buscar usuários:', error);
        }

    };

    // Função para buscar produtos pelo ID do usuário no backend
    const fetchUserProductsByUserId = async (id) => {
        try {
            const response = await axios.post(URLS.READ_BY_USER_ID_PRODUTO_USUARIO, {
                idUser: id
            });

            if (response.data.success) {
                const userProducts = response.data.produtos;

                setProductList(userProducts);
            } else {
                setProductList([]);
            }
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        }
    };

    // Função para buscar resultados pelo ID do usuário no backend
    const fetchUserResultsByUserId = async (id) => {
        try {
            const response = await axios.post(URLS.READ_BY_USER_ID_RESULTADO, {
                idUser: id
            });

            if (response.data.success) {
                const userResults = response.data.resultados;

                setResultList(userResults);
            } else {
                setResultList([]);
            }
        } catch (error) {
            console.error('Erro ao buscar resultados:', error);
        }
    };

    // Função para lidar com a mudança de entrada na barra de pesquisa
    const handleInputChange = (e) => {
        const value = e.target.value;

        setQuery(value);
        setShowUserList(false);

        if (value.length >= 4) {
            setIsLoading(true);
            setLoaderMessage("Buscando usuários");

            fetchUsersByName(value);

            setLoaderMessage('');
            setIsLoading(false);
        } else {
            setSuggestions([]);
        }
    };

    // Função para lidar com a seleção de um nome de usuário
    const handleSelectUser = (nome) => {
        const usersWithSameName = userList.filter(user => user.nome === nome);
        setUserList(usersWithSameName);
        setShowUserList(true);
        setSuggestions([]);
        setQuery('');
    };

    // Função para realizar a pesquisa ao pressionar "Enter" ou clicar no botão de pesquisa
    const handleSearch = () => {
        if (query.length > 0) {
            setIsLoading(true);
            setLoaderMessage("Buscando usuários");

            fetchUsersByName(query);
            setQuery('');

            setLoaderMessage('');
            setIsLoading(false);
            setShowUserList(true);

            if(userList.length > 0) {
                setNoUserFind(false);
                setSuggestions([]);
            } else {
                setNoUserFind(true);
            }
        } else {
            M.toast({
                html: 'Digite algum nome!',
                classes: 'toast_custom'
            });
        }
    };

    const onSubmitUser = async (e) => {
        e.preventDefault();
    
        const userData = {
            nome: nomeUsu,
            cpf: cpfUsu,
            email: emailUsu,
            telefone: telefoneUsu,
            senha: passUsu
        };
    
        setIsLoading(true);
        setLoaderMessage("Enviando dados do usuário");
    
        try {
            const result = await submitUserCreation(userData); // função para enviar os dados
    
            if (result.status) {
                // Sucesso na criação do usuário
                M.toast({
                    html: result.message,
                    classes: 'toast_custom'
                });
            } else {
                // Falha no processo
                M.toast({
                    html: result.message,
                    classes: 'toast_custom'
                });
            }
    
        } catch (error) {
            console.error('Erro ao adicionar usuário:', error);
            
            M.toast({
                html: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                classes: 'toast_custom'
            });
        }
    
        setLoaderMessage('');
        handleCloseUserAddModal();
        setIsLoading(false);
    };

    const onEditUser = async (e) => {
        e.preventDefault();
    
        const userData = {
            nome: userNameEdit,
            email: userEmailEdit,
            novaSenha: userPassEdit,
            idUser: selectedUser.id,
            id: idUser,
            senha: senha
        };
    
        setIsLoading(true);
        setLoaderMessage("Enviando dados do usuário");
    
        try {
            const result = await submitUserUpdate(userData); // função para enviar os dados
    
            if (result.status) {
                // Sucesso na edição do usuário
                M.toast({
                    html: result.message,
                    classes: 'toast_custom'
                });
            } else {
                // Falha no processo
                M.toast({
                    html: result.message,
                    classes: 'toast_custom'
                });
            }
    
        } catch (error) {
            console.error('Erro ao editar usuário:', error);
            
            M.toast({
                html: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                classes: 'toast_custom'
            });
        }
    
        setShowUserList(false);
        setLoaderMessage('');
        handleCloseEditSignModal();
        setIsLoading(false);
    };

    // Função para excluir um usuário
    const onDeleteUser = async () => {
        if (!selectedUser || !selectedUser.id) {
            M.toast({
                html: 'Selecione um usuário para excluir!',
                classes: 'toast_custom'
            });

            return; // Verifica se há um usuário selecionado
        }

        // Preparando os dados para a requisição de exclusão
        const deleteData = {
            id: selectedUser.id, // Usando o id do usuário para identificá-lo no banco de dados
            auth: 'C$CKMkyaYNq5Dx?s' // Token de autenticação para a API
        };

        setIsLoading(true);
        setLoaderMessage("Excluindo usuário");

        try {
            // Fazendo a requisição para excluir o usuário no backend
            const response = await fetch(URLS.DELETE_USUARIO, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteData),
            });

            const data = await response.json();

            if (data.success) {
                // Sucesso na exclusão do usuário

                // Log para fins de depuração
                console.log(`Usuário "${selectedUser.nome}" foi excluído com sucesso`);

                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            }
        } catch (error) {
            console.error('Erro ao excluir usuário:', error);

            M.toast({
                html: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                classes: 'toast_custom'
            });
        }

        setLoaderMessage('');
        setIsLoading(false);
        handleCloseEditSignModal();
        setShowUserList(false);
    };

    const handleCloseUserAddModal = () => {
        setCpfUsu('');
        setNomeUsu('');
        setEmailUsu('');
        setTelefoneUsu('');
        setPassUsu('');
        setIsUserAddModalOpen(false);
    }

    // Função para abrir o modal com os dados do usuário selecionado
    const handleOpenUserModal = async (user) => {
        setSelectedUser(user);
        setIsUserModalOpen(true);

        setIsLoading(true);
        setLoaderMessage("Buscando assinaturas do usuário");

        await fetchUserProductsByUserId(user.id); // Buscar os produtos do usuário

        setLoaderMessage("Buscando resultados do usuário");

        await fetchUserResultsByUserId(user.id); // Buscar os resultados do usuário

        setLoaderMessage(''); 
        setIsLoading(false);
    };

    // Função para fechar o modal
    const handleCloseUserModal = () => {
        setIsUserModalOpen(false);
        setSelectedUser(null);
        setProductList([]); // Limpar a lista de produtos ao fechar o modal
        setResultList([]); // Limpar a lista de resultados ao fechar o modal
    };

    // Função para abrir o modal com os dados do usuário selecionado
    const handleOpenEditSignModal = async (user) => {
        setUserNameEdit(user.nome);
        setUserEmailEdit(user.email);
        
        setSelectedUser(user);
        setIsEditSignModalOpen(true);

        setIsLoading(true);
        setLoaderMessage("Buscando assinaturas do usuário");

        await fetchUserProductsByUserId(user.id); // Buscar os produtos do usuário

        setLoaderMessage('');
        setIsLoading(false);
    };

    // Função para fechar o modal de edição e limpar os campos de formulário
    const handleCloseEditSignModal = () => {
        setIsEditSignModalOpen(false);
        setUserNameEdit('');
        setUserEmailEdit('');
        setUserPassEdit('');
        setSelectedUser(null);
        setProductList([]);
        setResultList([]);
        setSelectedAddProduct('');  // Limpar o campo de adicionar produto
        setSelectedRemoveProduct('');  // Limpar o campo de remover produto
    };

    // Função para adicionar um produto à assinatura do usuário
    const onSubmitAddProduct = async (event) => {
        event.preventDefault(); // Previne o comportamento padrão do form

        // Verifica se o usuário e o produto foram selecionados
        if (!selectedUser || !selectedAddProduct) {
            alert('Por favor, selecione um produto e tente novamente.');
            return;
        }

        // Criação dos dados necessários para enviar à API
        const productData = {
            idProduto: selectedAddProduct,  // ID do produto selecionado no modal
            idUser: selectedUser.id,    // ID do usuário selecionado
            idLogged: idUser,
            senha: senha             // Senha do usuário logado
        };

        setIsLoading(true);
        setLoaderMessage("Enviando dados da assinatura");

        // Chama a função que faz a requisição para adicionar a assinatura de produto
        const result = await submitProductSubscription(productData);

        // Verifica o status da operação e exibe a mensagem correspondente
        if (result.status) {
            M.toast({
                html: result.message,
                classes: 'toast_custom'
            });
        } else {
            M.toast({
                html: result.message,
                classes: 'toast_custom'
            });
        }

        setLoaderMessage('');
        setIsLoading(false);
        handleCloseEditSignModal(); // Fecha o modal após sucesso
    };

    const onSubmitRemoveProduct = async (event) => {
        event.preventDefault(); // Impede o envio padrão do formulário
    
        if (!selectedRemoveProduct) {
            alert("Selecione um produto para remover!");
            return; // Verifica se há um produto selecionado
        }
    
        setIsLoading(true);
        setLoaderMessage("Removendo assinatura");

        try {
            const response = await fetch(URLS.ASSINA_PRODUTO_DELETE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: selectedRemoveProduct,  // ID do produto a ser removido
                    idUser: selectedUser.id,     // ID do usuário
                    idLogged: idUser,
                    senha: senha,                // Senha do usuário
                    auth: 'C$CKMkyaYNq5Dx?s'     // Campo 'auth' conforme requerido pelo backend
                }),
            });

            const data = await response.json();
    
            if (data.success) {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                }); // Exibe a mensagem de erro vinda do servidor
            }
    
        } catch (error) {
            console.error("Erro ao excluir o produto:", error);
        }

        setLoaderMessage('');
        // Fecha o modal de edição de assinaturas
        handleCloseEditSignModal();
        setIsLoading(false);
    };

    // Função genérica para aplicar máscaras de formatação com verificação de limite de dígitos
    const applyMask = (value, maxLength, maskPatterns) => {
        let maskedValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos

        // Limita ao número máximo de dígitos especificado
        if (maskedValue.length > maxLength) maskedValue = maskedValue.slice(0, maxLength);

        // Aplica os padrões de máscara
        maskPatterns.forEach(([pattern, replacement]) => {
            maskedValue = maskedValue.replace(pattern, replacement);
        });
        return maskedValue;
    };

    // Manipuladores de eventos usando a função de máscara genérica com verificação de dígitos
    const handleCpfChange = (e) => {
        const formattedCpf = applyMask(e.target.value, 11, [
            [/(\d{3})(\d)/, '$1.$2'],
            [/(\d{3})(\d)/, '$1.$2'],
            [/(\d{3})(\d{1,2})$/, '$1-$2']
        ]);
        setCpfUsu(formattedCpf); // Atualiza o estado com o CPF formatado
    };

    const handleTelefoneChange = (e) => {
        const formattedTelefone = applyMask(e.target.value, 11, [
            [/(\d{2})(\d)/, '($1) $2'],
            [/(\d{5})(\d)/, '$1-$2']
        ]);
        setTelefoneUsu(formattedTelefone); // Atualiza o estado com o telefone formatado
    };

    // Funções de formatação reutilizáveis usando a função genérica com verificação de dígitos
    const formatCpf = (cpf) => applyMask(cpf, 11, [
        [/(\d{3})(\d)/, '$1.$2'],
        [/(\d{3})(\d)/, '$1.$2'],
        [/(\d{3})(\d{1,2})$/, '$1-$2']
    ]);

    const formatTelefone = (telefone) => applyMask(telefone, 11, [
        [/(\d{2})(\d)/, '($1) $2'],
        [/(\d{5})(\d)/, '$1-$2']
    ]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(); // Executa a pesquisa ao pressionar "Enter"
            e.preventDefault(); // Evita comportamentos inesperados
            e.stopPropagation();
        }
    };

    return (
        <div className="ConUsuarios">

            {/* Barra de pesquisa com autocomplete */}
            <div className='input_searchbar row'>
                <div className='col l8 s12'>
                    <h4 className='text_title_conts'>Digite o nome do usuário:</h4>
                </div>

                <div className='col s10'>
                    <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress} // Detecta "Enter"
                    />
                </div>

                <div className='col s2'>
                    {/* Botão de pesquisa */}
                    <button onClick={handleSearch} className="btn-floating btn-large round_buttons_user waves-effect waves-light"><i class="material-icons">search</i></button>
                    <button onClick={() => setIsUserAddModalOpen(true)} className="btn-floating btn-large round_buttons_user waves-effect waves-light"><i class="material-icons">add</i></button> 
                </div>
            </div>
            
            {/* Sugestões de autocomplete */}
            {(suggestions.length > 0 && query.length >= 4) && (
                <ul style={{ border: '1px solid #ccc', maxHeight: '150px', overflowY: 'auto', padding: '10px' }}>
                    {suggestions.map((nome, index) => (
                        <li 
                            key={index} 
                            onClick={() => handleSelectUser(nome)}
                            style={{ cursor: 'pointer', padding: '5px', borderBottom: '1px solid #eee' }}
                        >
                            {nome}
                        </li>
                    ))}
                </ul>
            )}

            { showUserList ? (
                noUserFind ? (
                    <h3>Nenhum usuário encontrado.</h3>
                ) : (
                    userList.length > 0 && (
                        <div className="user-list">
                            <h4 className='text_title_conts'>Usuários Encontrados:</h4>
                            <ul className='user_list'>
                                {userList.map((user, index) => (
                                    <div className='row div_user_list_item' key={index}>
                                        <div className='col l10 s12'>
                                            <li 
                                                className='user_list_item'
                                                onClick={() => handleOpenUserModal(user)} // Abre o modal ao clicar
                                            >
                                                <p>{user.nome} - {formatTelefone(user.telefone)}</p>
                                            </li>
                                        </div>
                                        <div className='col l2 s12'>
                                            <button
                                                onClick={() => handleOpenEditSignModal(user)}
                                                className="waves-effect waves-light waves-white btn left buttons_user_list"
                                            >
                                                Editar
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    )
                )
            ) : null }

            {/* ----------------------------------- Seção dos Modais ----------------------------------- */}

            {/* Modal com os dados do usuário */}
            <Modal
                isOpen={isUserModalOpen}
                onRequestClose={handleCloseUserModal}
                contentLabel="Dados do Usuário"
                style={customStyles}
                ariaHideApp={false}
            >
                {selectedUser ? (
                    <div className="row div_forms">
                        <h4 className="text_title_show_modal">{selectedUser.nome}</h4>

                        <div className='col s12'>
                            <p className="text_show_modal">
                                <strong>Email:</strong> {selectedUser.email}
                            </p>
                            <p className="text_show_modal">
                                <strong>Telefone:</strong> {formatTelefone(selectedUser.telefone)}
                            </p>
                            <p className="text_show_modal">
                                <strong>CPF:</strong> {formatCpf(selectedUser.cpf)}
                            </p>
                            <p className="text_show_modal">
                                <strong>Último IP:</strong> {selectedUser.last_ip}
                            </p>
                        </div>
                        
                        <div className='col s12'>
                            {/* Exibição da lista de produtos do usuário */}
                            {productList.length > 0 ? (
                                <div>
                                    <h5 className="text_title_show_modal">Produtos do Usuário:</h5>

                                    <ul>
                                        {productList.map((product, index) => {
                                            const productName = tags.find((tag) => tag.idTag === product.idProduto)?.nome || 'Nome do Produto Desconhecido';

                                            return (
                                                <li key={index}>
                                                    <p className="text_show_modal">
                                                        <strong>Produto:</strong> {productName} - <strong>Momento:</strong> {product.momento}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ) : (
                                <h5 className="text_title_show_modal">Nenhum produto encontrado para este usuário.</h5>
                            )}
                        </div>
                        
                        <div className='col s12'>
                            {/* Exibição da lista de resultados do usuário */}
                            {resultList.length > 0 ? (
                                <div>
                                    <h5 className="text_title_show_modal">Resultados do Usuário:</h5>

                                    <ul>
                                        {resultList.map((result, index) => {
                                            const testName = tests.find((test) => test.idTest === result.idTeste)?.nome || 'Nome do Teste Desconhecido';

                                            return (
                                                <li key={index}>
                                                    <p className="text_show_modal">
                                                        <strong>Teste:</strong> {testName} - <strong>Momento:</strong> {result.momento}
                                                    </p>

                                                    {/* Exibição formatada do campo estrutura */}
                                                    <div>
                                                        <p className="text_show_modal">
                                                            <strong>Estrutura:</strong>
                                                        </p>
                                                        {(() => {
                                                            try {
                                                                const estruturaObj = JSON.parse(result.estrutura);

                                                                return (
                                                                    <ul>
                                                                        {Object.entries(estruturaObj).map(([key, value], idx) => (
                                                                            <li key={idx}>
                                                                                <p className="text_show_modal_sub">
                                                                                    <strong>{key}:</strong>{' '}
                                                                                    {typeof value === 'boolean' ? (value ? 'Sim' : 'Não') : value}
                                                                                </p>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                );
                                                            } catch (e) {
                                                                // Se não for possível fazer o parse, exibe a estrutura como está
                                                                return <p>{result.estrutura}</p>;
                                                            }
                                                        })()}
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ) : (
                                <h5 className="text_title_show_modal">Nenhum resultado encontrado para este usuário.</h5>
                            )}
                        </div>

                        <div className="col s12">
                            <button
                                onClick={handleCloseUserModal}
                                className="waves-effect waves-light waves-white btn left buttons_forms"
                            >
                                Fechar
                            </button>
                        </div>
                        
                    </div>
                ) : (
                    <p>Carregando dados...</p>
                )}
            </Modal>
            
            {/* Modal de adição de usuário */}
            <Modal 
                isOpen={isUserAddModalOpen}
                onRequestClose={handleCloseUserAddModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Adição de Usuários"
            >
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onSubmitUser}>
                        <div className="row">
                            <div className="col s12 div_input">
                                <label htmlFor="nomeUsu">Nome do usuário</label>
                                <input
                                    id="nomeUsu"
                                    type="text"
                                    className="validate"
                                    minLength={4}
                                    required
                                    value={nomeUsu}
                                    onChange={(e) => setNomeUsu(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>

                            <div className="col s12 div_input">
                                <label htmlFor="cpfUsu">CPF</label>
                                <input
                                    id="cpfUsu"
                                    type="text"
                                    className="validate"
                                    required
                                    value={cpfUsu}
                                    onChange={handleCpfChange}
                                    autoComplete="off"
                                />
                            </div>

                            <div className="col s12 div_input">
                                <label htmlFor="emailUsu">E-mail</label>
                                <input
                                    id="emailUsu"
                                    type="email"
                                    className="validate"
                                    required
                                    value={emailUsu}
                                    onChange={(e) => setEmailUsu(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>

                            <div className="col s12 div_input">
                                <label htmlFor="telefoneUsu">Telefone</label>
                                <input
                                    id="telefoneUsu"
                                    type="text"
                                    className="validate"
                                    required
                                    value={telefoneUsu}
                                    onChange={handleTelefoneChange}
                                    autoComplete="off"
                                />
                            </div>

                            <div className="col s12 div_input">
                                <label htmlFor="passUsu">Senha</label>
                                <input
                                    id="passUsu"
                                    type="password"
                                    className="validate"
                                    required
                                    minLength={8}
                                    value={passUsu}
                                    onChange={(e) => setPassUsu(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>

                            <div className="col s6">
                                <button onClick={handleCloseUserAddModal} className="waves-effect waves-light waves-white btn left buttons_forms">
                                    Fechar
                                </button>
                            </div>
                            <div className="col s6">
                                <button className="waves-effect waves-light waves-white btn right buttons_forms" type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Modal para editar usuário */}
            <Modal
                isOpen={isEditSignModalOpen}
                onRequestClose={handleCloseEditSignModal}
                contentLabel="Editar Assinatura"
                style={customStyles}
                ariaHideApp={false}
            >
                {selectedUser ? (
                    <>
                        <div className="row div_forms">
                            <h4 className="text_title_show_modal">Editar Assinaturas de {selectedUser.nome}</h4>

                            {/* Formulário Adicionar assinatura */}
                            <div className="col s12">
                                <h5 className='text_add_title_sign'>Adicionar Assinatura</h5>

                                <form onSubmit={onSubmitAddProduct}>
                                    <div className='row'>
                                        <div className='col s12'>
                                            <label className='text_add_sign' htmlFor="add-signature">Selecione o produto para adicionar:</label>

                                            <select 
                                                id="add-signature" 
                                                className="browser-default z-depth-1" 
                                                value={selectedAddProduct} 
                                                onChange={(e) => setSelectedAddProduct(e.target.value)} 
                                                required
                                            >
                                                <option value="" disabled>Selecione um produto</option>
                                                {tags
                                                    .filter(tag => !productList.some(product => product.idProduto === tag.idTag))
                                                    .map((tag) => (
                                                        <option key={tag.idTag} value={tag.idTag}>
                                                            {tag.nome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className='col s12'>
                                            <button type="submit" className="waves-effect waves-light btn buttons_add_form_sign">Adicionar Produto</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {/* Formulário Excluir assinatura */}
                            <div className="col s12">
                                <h5 className='text_add_title_sign'>Excluir Assinatura</h5>

                                <form onSubmit={onSubmitRemoveProduct}>
                                    <div className='row'>
                                        <div className='col s12'>
                                            <label className='text_add_sign' htmlFor="remove-signature">Selecione o produto para remover:</label>

                                            <select 
                                                id="remove-signature" 
                                                className="browser-default tag_dropdown_input z-depth-1" 
                                                value={selectedRemoveProduct} 
                                                onChange={(e) => setSelectedRemoveProduct(e.target.value)} 
                                                required
                                            >
                                                <option value="" disabled>Selecione um produto</option>
                                                {productList.map((product) => (
                                                    <option key={product.idProduto} value={product.idProduto}>
                                                        {tags.find(tag => tag.idTag === product.idProduto)?.nome || 'Produto Desconhecido'}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        
                                        <div className='col s12'>
                                            <button type="submit" className="waves-effect waves-light btn red buttons_add_form_sign">Remover Produto</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="row div_forms">
                            <h4 className="text_title_show_modal">Editar Dados de {selectedUser.nome}</h4>

                            {/* Formulário editar usuário */}
                            <div className="col s12">
                                <form onSubmit={onEditUser}>
                                    <div className='row'>
                                        <div className="col s12">
                                            <label htmlFor="editTagName">Novo nome do usuário</label>
                                            <input 
                                                id="editTagName" 
                                                type="text" 
                                                className="validate" 
                                                required
                                                minLength={4}
                                                value={userNameEdit}
                                                onChange={(e) => setUserNameEdit(e.target.value)} 
                                                autoComplete="off" 
                                            />
                                        </div>

                                        <div className="col s12">
                                            <label htmlFor="editTagName">Novo e-mail do usuário</label>
                                            <input 
                                                id="editTagName" 
                                                type="email" 
                                                className="validate" 
                                                required 
                                                value={userEmailEdit}
                                                onChange={(e) => setUserEmailEdit(e.target.value)} 
                                                autoComplete="off" 
                                            />
                                        </div>

                                        <div className="col s12">
                                            <label htmlFor="editTagName">Nova senha do usuário</label>
                                            <input 
                                                id="editTagName" 
                                                type="password" 
                                                className="validate" 
                                                required
                                                minLength={8}
                                                value={userPassEdit}
                                                onChange={(e) => setUserPassEdit(e.target.value)} 
                                                autoComplete="off" 
                                            />
                                        </div>

                                        <div className="col s12">
                                            <button type="button" onClick={onDeleteUser} className="waves-effect waves-light waves-white btn red buttons_add_form_sign">
                                                Excluir
                                            </button>
                                        </div>

                                        <div className="col l6 s12">
                                            <button
                                                onClick={handleCloseEditSignModal}
                                                className="waves-effect waves-light btn buttons_add_form_sign"
                                            >
                                                Fechar
                                            </button>
                                        </div>

                                        <div className='col l6 s12'>
                                            <button type="submit" className="right waves-effect waves-light btn buttons_add_form_sign">Confirmar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </>
                ) : (
                    <p>Carregando dados...</p>
                )}
            </Modal>

        </div>
    );
}

export default ConUsuarios;