import React, { useState, useEffect } from 'react';
import './Loader.css';

const Loader = ( { message } ) => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => {
                if (prevDots.length < 3) {
                    return prevDots + '.';
                } else {
                    return '';
                }
            });
        }, 500); // A cada 500ms, adiciona um ponto até 3, então reseta
        
        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, []);

    return (
        <div className="loader-overlay">
            <div className="loader"></div>
            
            <div>
                <h5 className='text_loader'>{ message }{ dots }</h5> {/* Mensagem com os pontos animados */}
            </div>
        </div>
    );
};

export default Loader;