import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Loader from '../loader/Loader';
import { URLS } from '../../../constants/urls'
import M from 'materialize-css';

function ConInstitucional({ loaderMessage, setLoaderMessage, isLoading, setIsLoading, cpf, senha, idUser, institucionais, setInstitucionais, handleModalOpen, customStyles }) {
    const [isInstitucionalFormModalOpen, setIsInstitucionalFormModalOpen] = useState(false);

    const [nomeInstitucional, setNomeInstitucional] = useState('');
    const [tipoInstitucional, setTipoInstitucional] = useState('');
    const [valorInstitucional, setValorInstitucional] = useState('');

    // Função para fazer a requisição de todas as instituições
    const fetchInstitucional = async () => {
        setIsLoading(true);
        setLoaderMessage("Buscando dados do institucional");

        try {
            // Fazendo a requisição para obter todas as instituições
            const response = await fetch(URLS.READ_ALL_INSTITUCIONAL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idUser, senha }), // Enviando idUser e senha no corpo da requisição
            });

            const data = await response.json();

            if (data.success) {
                // Mapeando as instituições retornadas
                const fetchedInstitucional = data.institucional.map(inst => ({
                    id: inst.id,
                    nome: inst.name,
                    tipo: inst.type,
                    valor: inst.value,
                    momento: inst.momento,
                    idUser: idUser,
                    senha: senha,
                }));

                setInstitucionais(fetchedInstitucional);  // Atualiza o estado com as instituições obtidas
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error('Erro ao buscar instituições:', error);
        }

        setLoaderMessage('');
        setIsLoading(false);
    };

    // Função de submissão do formulário
    const onSubmitInstitucional = async (e) => {
        e.preventDefault();

        let idInstitucional;
        if (nomeInstitucional === 'sante' && tipoInstitucional === 'descricao') {
            idInstitucional = 1;
        } else if (nomeInstitucional === 'sante' && tipoInstitucional === 'video') {
            idInstitucional = 3;
        } else if (nomeInstitucional === 'livro' && tipoInstitucional === 'descricao') {
            idInstitucional = 5;
        } else if (nomeInstitucional === 'livro' && tipoInstitucional === 'video') {
            idInstitucional = 2;
        }

        try {
            let formData = new FormData();
            formData.append('id', idInstitucional);
            formData.append('nome', nomeInstitucional);
            formData.append('tipo', tipoInstitucional);
            formData.append('idUser', idUser);
            formData.append('senha', senha);

            // Se for um arquivo (vídeo), adiciona o arquivo ao FormData
            if (tipoInstitucional === 'video') {
                if (valorInstitucional instanceof File) {
                    formData.append('valor', valorInstitucional);
                } else {
                    alert('Por favor, selecione um arquivo de vídeo.');
                    return;
                }
            } else {
                // Se for uma descrição, adiciona o texto diretamente
                formData.append('valor', valorInstitucional);
            }

            setIsLoading(true);
            setLoaderMessage("Enviando dados do institucional");

            // Faz a requisição para o endpoint de atualização
            const response = await axios.post(URLS.UPDATE_INSTITUCIONAL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                M.toast({
                    html: response.data.message,
                    classes: 'toast_custom'
                });

                // Atualiza a lista de institucionais após a submissão
                await fetchInstitucional();
            } else {
                M.toast({
                    html: response.data.message,
                    classes: 'toast_custom'
                });
            }
        } catch (error) {
            console.error('Erro ao atualizar o institucional:', error);
        }

        setLoaderMessage('');
        setIsLoading(false);
        handleCloseInstitucionalFormModal();
    };  

    // Função para abrir o modal
    const handleOpenInstitucionalFormModal = () => {
        setIsInstitucionalFormModalOpen(true);
    };

    // Função para fechar o modal e limpar os campos do formulário
    const handleCloseInstitucionalFormModal = () => {
        setIsInstitucionalFormModalOpen(false);
        setNomeInstitucional(''); // Limpa os valores do formulário
        setTipoInstitucional(''); 
        setValorInstitucional('');
    };

    // Chama a função para buscar os dados ao carregar o componente
    useEffect(() => {
        fetchInstitucional();
    }, []);

    return (
        <div className='ConInstitucional'>

            <div className='row'>

                <div className='col s12 row'>
                    {institucionais.length > 0 ? (
                        // Agrupa institucionais por nome (ex: 'sante', 'livro')
                        ['sante', 'livro'].map((nomeGrupo) => {
                            const instDescricao = institucionais.find(inst => inst.nome === nomeGrupo && inst.tipo === 'descricao');
                            const instVideo = institucionais.find(inst => inst.nome === nomeGrupo && inst.tipo === 'video');

                            return (
                                (instDescricao || instVideo) && (
                                    <div key={nomeGrupo} className="col s12 m6">
                                        <h3 className='text_title_conts'>
                                            {nomeGrupo === 'sante' ? 'AcesseaMente' : 'Livro'}
                                        </h3>

                                        {/* Exibe a descrição, se houver */}
                                        {instDescricao && (
                                            <div>
                                                <p className='text_show'>{instDescricao.valor}</p>
                                            </div>
                                        )}

                                        {/* Exibe o vídeo, se houver */}
                                        {instVideo && (
                                            <div className="video-container">
                                                <video className='responsive-video' controls>
                                                    <source src={instVideo.valor} type="video/mp4" />
                                                    Seu navegador não suporta a exibição de vídeos.
                                                </video>
                                            </div>
                                        )}

                                        {/* Exibe o momento (data) do vídeo ou da descrição */}
                                        {instDescricao && (
                                            <p className='text_show'><strong>Momento (descrição):</strong> {instDescricao.momento}</p>
                                        )}
                                        {instVideo && (
                                            <p className='text_show'><strong>Momento (vídeo):</strong> {instVideo.momento}</p>
                                        )}
                                    </div>
                                )
                            );
                        })
                    ) : (
                        <p>Nenhum dado encontrado.</p>
                    )}
                </div>

                <div className='col s12 div_buttons_show'>
                    <button onClick={() => handleOpenInstitucionalFormModal()} className="waves-effect waves-light waves-white btn buttons_show">Editar</button>
                </div>

            </div>


            {/* ----------------------------------- Seção dos Modais ----------------------------------- */}

            {/* Modal de edição de institucional */}
            <Modal 
                isOpen={isInstitucionalFormModalOpen}
                onRequestClose={handleCloseInstitucionalFormModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Edição de Institucional"
            >
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onSubmitInstitucional}>
                        <div className="row">
                            {/* Nome - Radio buttons */}
                            <div className="col s12">
                                <label>Nome do institucional</label>
                                <p>
                                    <label>
                                        <input
                                            name="nomeInstitucional"
                                            type="radio"
                                            value="sante"
                                            checked={nomeInstitucional === 'sante'}
                                            onChange={() => setNomeInstitucional('sante')}
                                            required
                                        />
                                        <span>Santé</span>
                                    </label>
                                </p>
                                <p>
                                    <label>
                                        <input
                                            name="nomeInstitucional"
                                            type="radio"
                                            value="livro"
                                            checked={nomeInstitucional === 'livro'}
                                            onChange={() => setNomeInstitucional('livro')}
                                            required
                                        />
                                        <span>Livro</span>
                                    </label>
                                </p>
                            </div>

                            {/* Tipo - Radio buttons */}
                            <div className="col s12">
                                <label>Tipo</label>
                                <p>
                                    <label>
                                        <input
                                            name="tipoInstitucional"
                                            type="radio"
                                            value="descricao"
                                            checked={tipoInstitucional === 'descricao'}
                                            onChange={() => setTipoInstitucional('descricao')}
                                            required
                                        />
                                        <span>Descrição</span>
                                    </label>
                                </p>
                                <p>
                                    <label>
                                        <input
                                            name="tipoInstitucional"
                                            type="radio"
                                            value="video"
                                            checked={tipoInstitucional === 'video'}
                                            onChange={() => setTipoInstitucional('video')}
                                            required
                                        />
                                        <span>Vídeo</span>
                                    </label>
                                </p>
                            </div>

                            {/* Valor ou Vídeo */}
                            <div className="col s12">
                                {tipoInstitucional === 'descricao' ? (
                                    <div>
                                        <label htmlFor="valorInstitucional">Descrição</label>
                                        <textarea 
                                            id="valorInstitucional" 
                                            className="materialize-textarea" 
                                            required 
                                            value={valorInstitucional} 
                                            onChange={(e) => setValorInstitucional(e.target.value)}
                                            autoComplete="off"
                                        ></textarea>
                                    </div>
                                ) : tipoInstitucional === 'video' ? (
                                    <div className="div_input_img file-field input-field">

                                        <div className="btn buttons_forms">
                                            <span>Escolher Vídeo</span>
                                            <input 
                                                type="file" 
                                                onChange={(e) => setValorInstitucional(e.target.files[0])} 
                                                accept="video/*"
                                                required 
                                            />
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" />
                                        </div>

                                    </div>
                                ) : ( null )}
                            </div>

                            {/* Botões de Fechar e Confirmar */}
                            <div className="col s6">
                                <button 
                                    onClick={handleCloseInstitucionalFormModal} 
                                    className="waves-effect waves-light waves-white btn left buttons_forms">
                                    Fechar
                                </button>
                            </div>
                            <div className="col s6">
                                <button 
                                    className="waves-effect waves-light waves-white btn right buttons_forms" 
                                    type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

        </div>
    );
}

export default ConInstitucional;